<template>
  <v-container fill-height>
    <v-layout>
      <v-flex xs12 sm8 md6 lg4 offset-sm2 offset-md3 offset-lg4>
        <v-card flat class="login-card">
          <password-recovery-card
            :status="status"
            :error="error"
            class="pa-4"
            :token="token"
          />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { PasswordRecoveryCard } from '@/components/User'

const props = {
  token: {
    type: String,
    default: ''
  }
}

export default {
  components: { PasswordRecoveryCard },
  props,
  computed: {
    ...mapState('users', ['error', 'status'])
  }
}
</script>
<style scoped>
.login-card {
  z-index: 9999;
  min-width: 400px;
  max-width: 500px;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
